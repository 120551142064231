<template>
  <el-popover
    placement="left-end"
    :width="640"
    :popper-class="{ 'chat-popover': true, hidden: !showGpt }"
    :show-arrow="false"
    trigger="click"
    :visible="visible"
  >
    <template #reference>
      <span class="popover-action" :class="{ isActive: visible, hidden: !showGpt }" @click="changVisible"></span>
    </template>
    <div class="popover-content__header">
      <div class="popover-content-header__title">
        <img width="32" :src="require('@/assets/img/home/gpt-icon-min.png')" alt="">
        <span style="margin-left: 8px;">{{ $t('chatgpt.chat.title') }}</span>
        <span style="margin-left: 12px;">
          <el-tooltip class="item" :effect="$isDark() ? 'dark' : 'light'" :content="$t('chatgpt.chat.descTip')" placement="top">
            <div class="go-gptinfo__btn" @click="goGptInfo">{{ $t('chatgpt.chat.desc') }}<svg-icon name="jiantou"></svg-icon></div>
          </el-tooltip>
        </span>
      </div>
      <div class="popover-content-header__action">
        <!-- <span @click="showDeleDialog">清空记录</span> -->
        <el-select v-if="isTestEnv" v-model="selectModel" @change="changeModel" placeholder="请选择GPT模型">
          <el-option
            v-for="item in gptModels"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <span class="close" @click="handleClose">
          <el-icon><Close /></el-icon>
        </span>
      </div>
    </div>
    <div class="popover-content__wrap" ref="contentWrap">
      <div class="message-item" :class="{ isOwner: item.isOwner }" v-for="(item, index) in messagesList" :key="index">
        <img class="item-icon" :class="{ isOwner: item.isOwner }" :src="getIconSrc(item)" alt="">
        <div class="item-content__wrap" :class="{ isOwner: item.isOwner }">
          <div class="item-content" :class="{ isOwner: item.isOwner }">
            <span v-if="item.loading" style="display: block; padding: 8px;">
              <span>{{ item.message }}</span>
              <span class="loading-1">.</span>
              <span class="loading-2">.</span>
              <span class="loading-3">.</span>
            </span>
            <span class="markdown-content" v-else v-html="formatMarkdownStr(item.message, item.isOwner)"></span>
            <span class="squ"></span>
          </div>
          <span class="item-actions" v-if="index !== 0 && !item.loading" :class="{ isOwner: item.isOwner }">
            <svg-icon v-if="item.isOwner" style="font-size: 18px;color: #7c7f8d; margin-top: 7px; cursor: pointer;outline: none;" name="fuzhi1" @click="onCommand(`copy-${index}`)"></svg-icon>
            <el-dropdown v-else trigger="click" popper-class="message-popover" @command="onCommand">
              <svg-icon style="font-size: 32px;color: #7c7f8d; cursor: pointer;outline: none;" name="gengduo-heng"></svg-icon>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item class="copy-action" v-if="!item.notCopy" :command="`copy-${index}`">{{ $t('chatgpt.chat.copy') }}</el-dropdown-item>
                  <el-dropdown-item :disabled="isSending || !hasPermission" @click="handleReanswer(index)">{{ $t('chatgpt.chat.reanswer') }}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </span>
        </div>
      </div>
      <div class="stop-btn" v-show="isSending" @click="stopSend">
        <svg-icon style="font-size: 16px; margin-top: 2px;" name="yanzheng"></svg-icon>
        <span style="padding-left:5px;">{{ $t('chatgpt.chat.stop') }}</span>
      </div>
    </div>
    <div class="popover-content__footer">
      <div class="info-list__wrap">
        <div @click="messageVal = item" :class="{ disabled: isSending }" class="info-item" v-for="item in infoMessages" :key="item">{{ item }}</div>
      </div>
      <div style="padding-top: 10px; display: flex;align-items: center;">
        <el-tooltip class="item" :effect="$isDark() ? 'dark' : 'light'" :content="$t('chatgpt.chat.deleteTitle')" placement="top">
          <div class="clear-btn" :class="{ disabled: isSending }" style="margin-right: 10px;" @click="showDeleDialog"><svg-icon name="shanchudefuben"></svg-icon></div>
        </el-tooltip>
        <el-input id="chat-textarea" v-model="messageVal" size="large" :placeholder="$t('chatgpt.chat.placeholder')" type="textarea" :maxlength="100" :autosize="{ minRows: 1, maxRows: 5 }" :input-style="{ width: '522px' }" @keydown.enter="returnMessage($event)" />
        <!-- <el-button :class="{ disabled: !messageVal || isSending }" type="primary" style="margin-left: 10px;" @click="handleSend(messageVal)">发送</el-button> -->
        <div class="send-btn" :class="{ disabled: !messageVal || isSending || !hasPermission }" style="margin-left: 10px;" @click="handleSend(messageVal)"><svg-icon name="fasong1"></svg-icon></div>
      </div>
    </div>
  </el-popover>
  <el-dialog
    v-model="deleDialogVisible"
    :title="$t('chatgpt.chat.dialog.title')"
    width="470px"
  >
    <div class="delete-team__content">
      <svg-icon name="info"></svg-icon>
      <span style="line-height: 21px;">{{$t('chatgpt.chat.dialog.content')}}</span>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="deleDialogVisible = false">{{$t('dialog.cancel')}}</el-button>
        <el-button type="primary" @click="handleClear">{{ $t('dialog.confirm') }}</el-button>
      </span>
    </template>
  </el-dialog>
  <textarea style="position: fixed; z-index: -1;" ref="copyContent"></textarea>
  <UpgradeDialog
    v-model:show="isShowUpgrade"
    type="C1"
    :customText="$t('other.components.upgradeHunter')"
    :showPoint="false"
    :showNormal="true"
    upgradeType="enterprise"
  />
</template>

<script setup>
import UpgradeDialog from '@/components/common/upgradeDialog'
import { ref, nextTick, onMounted, defineProps, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { setItem, getItem } from 'utils'
import { postChatMessage } from '@/services/gpt.js'
import { message } from '@/hooks/useUI'
import { Close } from '@element-plus/icons-vue'
import MarkdownIt from 'markdown-it'
import { t, isZh } from 'app/i18n'

defineProps({
  showGpt: {
    default: false,
    type: Boolean
  }
})
const infoMessages = [
  t('chatgpt.chat.message.tip1'),
  t('chatgpt.chat.message.tip2'),
  t('chatgpt.chat.message.tip3'),
  t('chatgpt.chat.message.tip4'),
  t('chatgpt.chat.message.tip5')
]

const isTestEnv = window.location.host.indexOf('test.ata.360') > -1

const gptModels = [
  {
    label: 'GPT',
    value: 'gpt'
  },
  {
    label: '360',
    value: 'internal'
  }
]
let controlller = null
const route = useRoute()
const store = useStore()
const selectModel = ref('gpt')
const messageVal = ref('')
const contentWrap = ref()
const isSending = ref(false)
const deleDialogVisible = ref(false)
const isShowUpgrade = ref(false)
const messagesList = ref([
  // {
  //   message: '我是沙箱云聊天助手，很高兴能为您提供服务！您可以向我提出有关沙箱云的问题或指令，我将为您解答或执行您的指令。',
  //   isOwner: false
  // }
])
const copyContent = ref()
const hasPermission = computed(() => {
  if (!store.getters.userInfo.group || store.getters.userInfo.group === 'free' || store.getters.userInfo.group === 'professional') {
    return false
  }
  return true
})
const visible = ref(false)

onMounted(() => {
  if (store.state.user.gptList) {
    messagesList.value = store.state.user.gptList
    nextTick(() => {
      contentWrap.value && contentWrap.value.scrollTo({ top: contentWrap.value.scrollHeight, behavior: 'smooth' })
    })
  }
  if (isTestEnv && getItem('GPT_MODEL') && getItem('GPT_MODEL').user === store.state.user.userInfo.user_id) {
    selectModel.value = getItem('GPT_MODEL').model === 'internal' ? 'internal' : 'gpt'
  }
})

const getIconSrc = (item) => {
  if (item.isOwner) {
    if (store.getters.userInfo.user_pic && store.getters.userInfo.user_pic.type && store.getters.userInfo.user_pic.base64) {
      return `data:image/${store.getters.userInfo.user_pic.type};base64,${store.getters.userInfo.user_pic.base64}`
    }
    return require('@/assets/img/avatar.png')
  } else {
    return require('@/assets/img/home/gpt-icon-min.png')
  }
}

const showDeleDialog = () => {
  if (isSending.value) {
    message(t('chatgpt.chat.message.deleteWait'), 'error')
    return
  }
  deleDialogVisible.value = true
}

const goGptInfo = () => {
  window.open('/chat', '_blank')
}
// const formatMessage = (message) => {
//   if
// }

const changeModel = (val) => {
  setItem('GPT_MODEL', {
    model: val,
    user: store.state.user.userInfo.user_id
  })
}

const changVisible = () => {
  visible.value = !visible.value
  nextTick(() => {
    if (visible.value) {
      contentWrap.value && contentWrap.value.scrollTo({ top: contentWrap.value.scrollHeight, behavior: 'smooth' })
      renderMermaid()
    }
  })
}

const formatMarkdownStr = (str, isOwner) => {
  if (isOwner) {
    return str
  }
  const md = new MarkdownIt()
  const html = md.render(str)
  return html
}

const renderMermaid = () => {
  nextTick(() => {
    window.mermaid.initialize({
      startOnLoad: true,
      theme: 'default'
    })
    window.mermaid.init('pre>code.language-mermaid')
  })
}

// async function mermaidStrToSvg (str) {
//   const { svg } = await window.mermaid.render('graphDiv', str)
//   return svg
// }

const handleClose = () => {
  visible.value = false
}

const stopSend = () => {
  // console.log('取消')
  controlller && controlller.abort()
  messagesList.value[messagesList.value.length - 1].message = t('chatgpt.chat.message.stoped')
  messagesList.value[messagesList.value.length - 1].notCopy = true
  messagesList.value[messagesList.value.length - 1].loading = false
  store.commit('user/setGptList', messagesList.value)
}

// const formatMessage = (message) => {
//   if
// }

const onCommand = (command) => {
  if (command.indexOf('copy') === 0) {
    const index = parseInt(command.split('-')[1])
    copyContent.value.value = messagesList.value[index].message
    console.log(messagesList.value[index].message)
    if (index === messagesList.value.length - 1) {
      // 最后一条无法复制bug
      setTimeout(() => {
        copyContent.value.select()
        document.execCommand('copy')
        message(t('chatgpt.chat.message.copySuccessful'))
      }, 500)
    } else {
      copyContent.value.select()
      document.execCommand('copy')
      message(t('chatgpt.chat.message.copySuccessful'))
    }
  }
}

const handleReanswer = (index) => {
  handleSend(messagesList.value[index - 1].message, true)
}

const handleClear = () => {
  deleDialogVisible.value = false
  messagesList.value = [{
    message: t('chatgpt.chat.message.tip'),
    isOwner: false
  }]
  store.commit('user/setGptList', messagesList.value)
}

const returnMessage = (event) => {
  const e = window.event || event
  if ((e.key === 'Enter' || e.code === 'Enter' || e.keyCode === 13) && e.shiftKey) {
    messageVal.value += '\n'
  } else {
    e.returnValue = false
    handleSend(messageVal.value)
    return false
  }
}

const handleSend = (sendVal, isReanswer) => {
  if (!store.getters.userInfo.group || store.getters.userInfo.group === 'free' || store.getters.userInfo.group === 'professional') {
    // 显示升级弹窗
    isShowUpgrade.value = true
    return
  }
  if (!sendVal) {
    message(t('chatgpt.chat.message.placeInput'), 'error')
    return
  }
  if (isSending.value) {
    message(t('chatgpt.chat.message.wait'), 'error')
    return
  }
  if (sendVal && !isSending.value) {
    isSending.value = true
    // 发送消息
    // if (!isReanswer) {
    messagesList.value.push({
      message: sendVal,
      isOwner: true
    })
    // }
    messagesList.value.push({
      message: t('chatgpt.chat.message.placeWait'),
      isOwner: false,
      loading: true
    })
    if (!isReanswer) {
      messageVal.value = ''
    }
    nextTick(() => {
      contentWrap.value && contentWrap.value.scrollTo({ top: contentWrap.value.scrollHeight, behavior: 'smooth' })
    })
    controlller = new AbortController()
    postChatMessage({
      input: sendVal,
      report_id: route.params.taskId,
      gpt_model: selectModel.value,
      lang: isZh() ? 'zh' : 'en'
    }, controlller.signal).then(res => {
      messagesList.value.pop()
      if (res.code) {
        if (res.code === 10012 || res.code === 10003) {
          messagesList.value.push({
            message: t('chatgpt.chat.message.reTry'),
            isOwner: false,
            notCopy: true
          })
        } else {
          messagesList.value.push({
            message: t('chatgpt.chat.message.notUnderstand'),
            isOwner: false
          })
        }
      } else {
        messagesList.value.push({
          message: res,
          isOwner: false
        })
        if (res.indexOf('mermaid\n') > -1) {
          nextTick(() => {
            renderMermaid()
          })
        }
      }
      store.commit('user/setGptList', messagesList.value)
      nextTick(() => {
        contentWrap.value && contentWrap.value.scrollTo({ top: contentWrap.value.scrollHeight, behavior: 'smooth' })
      })
      isSending.value = false
    }).catch((error) => {
      console.log(error, error.message)
      if (error.message.indexOf(`Cannot destructure property 'status' of`) !== 0) {
        if (error.response && error.response.status === 429) {
          if (error.response.data.rate.indexOf('m') > -1) {
            message(t('chatgpt.chat.message.tooHigh'), 'error')
            messagesList.value.pop()
            messagesList.value.pop()
          } else {
            messagesList.value.pop()
            messagesList.value.push({
              message: t('chatgpt.chat.message.runOut'),
              isOwner: false
            })
          }
        } else {
          messagesList.value.pop()
          messagesList.value.pop()
          message(t('chatgpt.chat.message.serverError'), 'error')
        }
        nextTick(() => {
          contentWrap.value && contentWrap.value.scrollTo({ top: contentWrap.value.scrollHeight, behavior: 'smooth' })
        })
      }
      isSending.value = false
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
[lang="en"] .chat-popover .popover-content__wrap {
  min-height: calc(100vh - 396px);
  .stop-btn {
    width: 158px;
    left: 244px;
  }
}
.chat-popover {
  .popover-content__header {
    display: flex;
    border-bottom: 1px solid #3f4251;
    padding: 12px 16px;
    line-height: 24px;
    align-items: center;
    justify-content: space-between;
    // flex-direction: row-reverse;
    .popover-content-header__title {
      font-size: 16px;
      font-family: shishangzhonghei;
      color: #ffffff;
      display: flex;
      align-items: center;
      .go-gptinfo__btn {
        font-size: 12px;
        font-family: PingFang SC;
        cursor: pointer;
        color: #00AB7A;
        line-height: 24px;
        // width: 60px;
        padding: 0 10px;
        text-align: center;
        height: 24px;
        border-radius: 12px;
        background-color: #323A45;
        &:hover {
          color: var(--color-priamry);
        }
      }
    }
    .popover-content-header__action {
      font-size: 14px;
      color: #b8bbcc;
      cursor: pointer;
      display: flex;
      align-items: center;
      :deep(.el-select) {
        width: 120px;
      }
      :deep(.el-input__wrapper) {
        border-radius: 4px;
      }
      &>span:hover {
        color: var(--color-priamry);
      }
      .close {
        font-size: 18px;
        margin-top: 5px;
        margin-left: 8px;
      }
    }
  }
  .popover-content__wrap {
    padding: 16px 16px 0 16px;
    // min-height: 200px;
    // max-height: 370px;
    position: relative;
    min-height: calc(100vh - 356px);
    // height: 560px;
    overflow-y: auto;
    .message-item {
      padding: 16px 0;
      // overflow: hidden;
      display: flex;
      position: relative;
      &.isOwner {
        flex-direction: row-reverse;
      }
      .item-icon {
        width: 36px;
        height: 36px;
        // float: left;
        border-radius: 18px;
      }
      .item-content__wrap {
        max-width: 560px;
        margin-left: 10px;
        display: flex;
        align-items: flex-end;
        &.isOwner {
          margin-left: 0;
          margin-right: 10px;
          flex-direction: row-reverse;
        }
        .item-content {
          color: #ffffff;
          border-radius: 2px;
          font-size: 14px;
          max-width: 496px;
          border-radius: 4px;
          // white-space: pre-wrap;
          // float: left;
          background: #282b39;
          border: 1px solid #5a5e6d4d;
          position: relative;
          // overflow-x: auto;
          .markdown-content {
            overflow-x: auto;
            overflow-x: overlay;
            @include scroll_bar;
            display: block;
            padding: 8px;
          }
          &>.squ {
            position: absolute;
            transform: rotate(45deg);
            border-left: 1px solid rgba(90, 94, 109, 0.3019607843);
            border-bottom: 1px solid rgba(90, 94, 109, 0.3019607843);
            width: 6px;
            height: 6px;
            top: 10px;
            left: -3px;
            background-color: #282b39;
          }
          &.isOwner {
            // float: right;
            color: #ffffff;
            background: #1DB887;
            border: 1px solid #5a5e6d4d;
            &>.squ {
              border: none;
              right: -3px;
              left: auto;
              background-color: #1DB887;
            }
          }
          .loading-1 {
            font-weight: bolder;
            // font-size: 18px;
            padding: 0 2px;
            animation: loading-1 2s ease 0.5s infinite normal;
          }
          .loading-2 {
            font-weight: bolder;
            padding: 0 2px;
            // font-size: 18px;
            animation: loading-2 2s ease 0.5s infinite normal;
          }
          .loading-3 {
            font-weight: bolder;
            padding: 0 2px;
            // font-size: 18px;
            animation: loading-3 2s ease 0.5s infinite normal;
          }
          :deep(pre) {
            opacity: 0.80;
            margin: 0;
            // padding: 10px 20px;
            white-space: pre-wrap; /* css-3 */
            word-wrap: break-word; /* InternetExplorer5.5+ */
            code {
              // width: 100%;
              &.language-mermaid {
                display: block;
                // min-width: 400px;
                // min-height: 200px;
                height: auto;
                .edgePath {
                  .path {
                    stroke: #7C7F8D !important;
                  }
                  defs {
                    marker {
                      fill: #7C7F8D;
                      path {
                        fill: #7C7F8D !important;
                      }
                    }
                  }
                }
              }
            }
          }
          :deep(code) {
            padding: 2px 3px;
          }
          :deep(table) {
            width: 100%;
            border: 1px solid #5a5e6d;
            border-collapse: collapse;
          }
          :deep(table+p) {
            margin-top: 20px;
          }
          :deep(p+table) {
            margin-top: 10px;
          }
          :deep(a) {
            color: #00ab7a;
          }
          :deep(p) {
            margin: 0;
          }
          :deep(h2) {
            font-size: 20px;
            font-weight: 600;
            // margin-bottom: 0;
          }
          :deep(h3) {
            margin-bottom: 0;
            font-weight: 600;
          }
          :deep(h4) {
            margin-bottom: 0;
            font-weight: 600;
          }
          :deep(h5) {
            margin-bottom: 0;
            font-weight: 600;
          }
          :deep(h6) {
            margin-bottom: 0;
            font-weight: 600;
          }
          :deep(th) {
            border: 1px solid #5a5e6d;
            font-weight: 600;
          }
          :deep(td) {
            padding: 5px;
            text-align: center;
            min-width: 70px;
            border: 1px solid #5a5e6d;
          }
          :deep(th) {
            text-align: center;
            min-width: 70px;
            padding: 5px;
          }
          :deep(ul) {
            padding-left: 20px;
          }
        }
        .item-actions {
          // float: left;
          // display: inline-block;
          text-align: center;
          // vertical-align: bottom;
          color: #AEB2B8;
          width: 32px;
          height: 32px;
          margin-bottom: 3px;
          background: #292e40;
          border-radius: 16px;
          line-height: 31px;
          margin-left: 8px;
          &.isOwner {
            // float: right;
            margin-right: 8px;
            margin-left: 0;
          }
        }
      }
    }
    .stop-btn {
      position: absolute;
      // bottom: 10px;
      left: 266px;
      z-index: 2000;
      width: 108px;
      height: 32px;
      line-height: 30px;
      padding: 0 12px;
      border-radius: 2px;
      background: rgba(254, 89, 103, 0.1);
      box-sizing: border-box;
      border: 1px solid #FE5967;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #FE5967;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
  .popover-content__footer {
    border-top: 1px solid #3F4251;
    margin-top: 10px;
    padding: 6px 16px 24px;
    :deep(.el-textarea__inner) {
      resize: none;
      background: #0F121B;
      box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),0px 6px 16px 0px rgba(0, 0, 0, 0.08),0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
    :deep(.el-textarea) {
      padding: 10px 0;
      border-radius: 4px;
      background: #0F121B;
      box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),0px 6px 16px 0px rgba(0, 0, 0, 0.08),0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
    .send-btn {
      width: 32px;
      height: 32px;
      text-align: center;
      cursor: pointer;
      line-height: 32px;
      font-size: 20px;
      border-radius: 16px;
      opacity: 1;
      color: #ffffff;
      background: #00B682;
      &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
    .clear-btn {
      width: 32px;
      height: 32px;
      text-align: center;
      cursor: pointer;
      line-height: 32px;
      font-size: 20px;
      border-radius: 16px;
      opacity: 1;
      color: #D8D8D8;
      background: #323A45;
      &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
    .info-list__wrap {
      overflow: hidden;
      .info-item {
        float: left;
        margin-top: 10px;
        margin-right: 8px;
        border-radius: 4px;
        font-size: 12px;
        border: 1px solid rgba(90, 94, 109, 0.3);
        padding: 0 10px;
        line-height: 28px;
        cursor: pointer;
        color: #B8BBCC;
        &.disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}
.popover-action {
  position: fixed;
  z-index: 20000;
  right: 30px;
  border-radius: 22px;
  cursor: pointer;
  display: block;
  width: 44px;
  height: 44px;
  // background-color: #323a45;
  background-color: var(--home-map-btn-bg);
  border-radius: 100%;
  background-size: 44px 44px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  bottom: 268px;
  background-image: url('~@/assets/img/index/gpt.png');
  &.hidden {
    display: none;
  }
}

[data-theme='light'] {
  .popover-action {
    box-shadow: 0 1px 5px 0 #c7c7c780;
    background-image: url('~@/assets/img/index/gpt.png');
    &.hidden {
      display: none;
    }
  }
  .chat-popover {
    .popover-content__header {
      border-bottom: 1px solid #E8EAED;
      // flex-direction: row-reverse;
      .popover-content-header__title {
        color: #282B39;
        .go-gptinfo__btn {
          color: #00AB7A;
          background-color: #FFFFFF;
        }
      }
      .popover-content-header__action {
        color: #b8bbcc;
        &>span:hover {
          color: var(--color-priamry);
        }
      }
    }
    .popover-content__wrap {
      .message-item {
        .item-content {
          background: #FFFFFF;
          border: 1px solid #DFE1E6;
          color: #242933;
          &>.squ {
            border-left: 1px solid #DFE1E6;
            border-bottom: 1px solid #DFE1E6;
            background-color: #FFFFFF;
          }
          &.isOwner {
            background: #1DB887;
            border: none;
            &>.squ {
              border: none;
              background-color: #1DB887;
            }
          }
          :deep(pre) {
            code {
              // width: 100%;
              &.language-mermaid {
                display: block;
                // min-width: 400px;
                // min-height: 200px;
                height: auto;
                .edgePath {
                  .path {
                    stroke: #8A9099 !important;
                  }
                  defs {
                    marker {
                      fill: #8A9099;
                      path {
                        fill: #8A9099 !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .item-actions {
          background: #FFFFFF;
        }
      }
      .stop-btn {
        background: rgba(254, 89, 103, 0.1);
        border: 1px solid #FE5967;
        color: #FE5967;
      }
    }
    .popover-content__footer {
      border-top: 1px solid #DFE1E6;
      :deep(.el-textarea__inner) {
        background-color: #FFFFFF;
        border: none;
        box-shadow: none;
      }
      :deep(.el-textarea) {
        background-color: #FFFFFF;
        box-shadow: none;
      }
      .send-btn {
        color: #ffffff;
        background: #00B682;
        &.disabled {
          opacity: 0.6;
          cursor: not-allowed;
        }
      }
      .clear-btn {
        color: #878D96;
        background-color: #FFFFFF;
      }
      .info-list__wrap {
        .info-item {
          background: #FFFFFF;
          border: 1px solid #DFE1E6;
          color: #242933;
        }
      }
    }
  }
}

@keyframes loading-1 {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes loading-2 {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  67% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes loading-3 {
  0% {
    opacity: 0;
  }
  67% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
