<template>
  <div class="tableRadio" ref="tableRadio">
    <div
      class="tableRadio__input"
      :class="{
        'tableRadio__input--isOpen': isOpen,
        'tableRadio__input--placeholder': !value
      }"
      @click="isOpen = !isOpen"
    >
      {{ value ? getInputData : placeholder }}
    </div>
    <template v-if="tableData.length !== 0">
      <div class="tableRadio__table" v-show="isOpen" ref="table">
        <div class="tableRadio__header">
          <div class="tableRadio__header--radio"></div>
          <div
            class="tableRadio__header--item"
            v-for="item in columnData"
            :key="item.prop"
            :style="
              `
              width: ${item.width ? getWidth(item.width) : '0px'};
              flex-grow: ${item.width ? '0' : item.col ? item.col : '1'};
              text-align: ${item.align ? item.align : 'left'};
            `
            "
          >
            {{ item.label }}
          </div>
        </div>
        <div class="tableRadio__body">
          <div
            class="tableRadio__body--tr"
            :class="{
              'tableRadio__body--trSelected': selected === optionData[index].value,
              'tableRadio__body--trDisabled': optionData[index].disabled
            }"
            v-for="(tr, index) in tableData"
            :key="optionData[index].value"
            @click="
              radioChange(
                optionData[index].value,
                optionData[index].disabled,
                optionData[index].level
              )
            "
          >
            <div class="tableRadio__body--radio">
              <div class="radio--dot"></div>
            </div>
            <div
              class="tableRadio__body--td"
              v-for="(td, tdIndex) in columnData"
              :key="tdIndex"
              :style="
                `
                width: ${td.width ? getWidth(td.width) : '0px'};
                flex-grow: ${td.width ? '0' : td.col ? td.col : '1'};
                text-align: ${td.align ? td.align : 'left'};
              `
              "
            >
              {{ tr[td.prop] }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted, onUnmounted, defineProps, defineEmits, toRefs, nextTick } from 'vue'
import { t } from 'app/i18n'

const props = defineProps({
  value: {
    type: String
  },
  placeholder: {
    type: String
  },
  optionData: {
    type: Array
  }
})

const emit = defineEmits(['disableClick', 'change', 'update:value'])

const table = ref(null)

const tableRadio = ref(null)

const { value, placeholder, optionData } = toRefs(props)

const selected = ref(value.value || (optionData.value[0] && optionData.value[0].value))

const isOpen = ref(false)

const columnData = computed(() => {
  let columns = []
  optionData.value.forEach((item) => {
    columns.push(...item.software.map((item) => item.alias))
  })
  columns = [...new Set(columns)]
  return columns.map((item) => ({
    prop: item,
    label: item
  }))
})
const tableData = computed(() => {
  const res2 = optionData.value.reduce((prev, cur) => {
    const tableItem = {}
    cur.software.forEach((i) => {
      tableItem[i.alias] = i.major
    })
    return Object.keys(tableItem).length ? prev.concat(tableItem) : prev
  }, [])
  return res2
})

const getInputData = computed(() => optionData.value.find((item) => value.value === item.value).label || t('config.basic.software_major.non_select'))

watch(optionData, () => { selected.value = value.value || optionData.value[0]?.value })

watch(value, () => { selected.value = value.value || optionData.value[0]?.value })

onMounted(() => {
  document.addEventListener('click', closeTable, true)
  if (table.value) {
    nextTick(() => {
      table.value.style.width = tableRadio.value.offsetWidth + 'px'
    })
  }
})

onUnmounted(() => {
  document.removeEventListener('click', closeTable, true)
})

function closeTable(e) {
  if (!tableRadio.value.contains(e.target)) {
    isOpen.value = false
  }
}
function getWidth(data) {
  if (data.indexOf('px') > 0) {
    return data
  }
  return data + 'px'
}
function radioChange(val, disabled, level) {
  if (disabled) {
    emit('disableClick')
    return
  }
  selected.value = val
  // v-modle需要得到value
  emit('update:value', val)
  // onchange需要得到lavel
  emit('change', level)
  // isOpen = false
}

</script>
<style lang="scss" scoped>
.tableRadio  {
  position: relative;
  &__input  {
    height: 32px;
    line-height: 32px;
    // width: calc(100% - 42px);
    overflow: hidden;
    padding-left: 12px;
    padding-right: 28px;
    border-radius: 4px;
    border: 1px solid var(--color-border-1);
    background-color: var(--detect-config-item-default);
    color: var(--color-text-1);
    position: relative;
    cursor: pointer;
    &:hover.tableRadio__input--placeholder  {
      color: var(--color-text-1);
    }
    &--placeholder  {
      color: var(--color-text-3);
    }
    &:after {
      content: url('../../../assets/img/detection/triangle1.svg');
      position: absolute;
      width: 1em;
      height: 1em;
      top: 9px;
      right: 12px;
      transform: rotateZ(0deg);
      transition: transform 0.3s;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
    &--isOpen {
      border-color: #00ab7a;
    }
    &--isOpen:after  {
      transform: rotateZ(180deg);
    }
  }
  &__table  {
    position: absolute;
    left: 0;
    top: 32px;
    width: 477px;
    border-radius: 4px;
    box-shadow: var(--shadow);
    overflow: hidden;
    font-size: 12px;
    margin-top: 11px;
    // background-color: #1A2244;
    background-color: var(--color-bg-2);
    backface-visibility: hidden;
    z-index: 1002;
  }
  &__header  {
    width: 100%;
    display: flex;
    min-height: 32px;
    background-color: var(--color-bg-3);
    color: var(--color-text-3);
    &--item  {
      padding: 6px 4px;
      word-break: break-all;
      display: flex;
      align-items: center;
    }
    &--radio  {
      width: 36px;
    }
  }
  &__body {
    &--radio  {
      width: 36px;
      & .radio--dot  {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        border: 1px solid var(--color-priamry);
        box-sizing: border-box;
        position: relative;
        left: calc(50% - 5px);
        top: calc(50% - 5px);
      }
    }
    &--td  {
      padding: 6px 4px;
      word-break: break-word;
      word-break: auto-phrase;
      // border-left: 1px dashed #394573;
      display: flex;
      align-items: center;
      // color: var( --color-unkown-text);
      color: var(--color-text-3);
      &:first-child  {
        border-left: none;
      }
    }
    &--tr  {
      display: flex;
      min-height: 32px;
      background-color: var(--detect-config-os);
      border-top: 1px solid var(--color-bg-4);
      cursor: pointer;
      &:first-child  {
        border-top: 1px solid var(--color-bg-4);
      }
      // &:nth-child(odd)  {
      //   background-color: #2A335D;
      // }
      // &:hover  {
      //   background-color: #374272;
      // }
      &Selected, &Selected:nth-child(odd) {
        // background-color: var(--color-bg-3);
        & .radio--dot  {
          background-color: #AFC4FE;
          border-width: 3px;
        }
      }
      &Disabled  {

        cursor: pointer;
        position: relative;
        &::before  {
          content: ' ';
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background-color: var(--detect-config-mask);
          z-index: 1001;
        }
      }
    }
  }
}
</style>
