<template>
<div class="public-list-option">
  <!-- <span v-if="newest.tag" class="public-list-tag">
    <span class="public-list-tag-name">标签: {{ newest.tag }}</span>
    <span class="public-list-tag-close" @click="handleTagClose">x</span>
  </span> -->
  <i class="option-filter">
    <el-button type="primary" v-show="showFilterBtn" plain :text="props.textBtn" @click="show = !show">
      {{$t('taskhub.filter.title')}}<el-icon class="el-icon--right"><ArrowDown :class="{'active' : show}"/></el-icon>
    </el-button>
  </i>
  <div class="filter-tag" v-if="!show">
    <span v-for="(item, index) in tags" :key="index">{{item}}</span>
  </div>
</div>
<teleport v-if="isLoaded" to="#filterWrapper">
  <!-- <el-collapse-transition> -->
    <div v-if="show" class="wrapper">
      <div class="filter-type" v-if="isGlobal">
        <p class="label">任务范围</p>
        <div class="filters filter-range">
          <el-radio-group v-model="filters.is_public_filter.value" @change="change">
            <el-radio v-for="(op, idx) in isPublistFilterOption" :key="idx" :label="op.value">
              {{op.label}}
            </el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="filter-type" v-for="(item, key) in KeyOrder" :key="key" :class="{filterRange:item === 'score', dayRange:item === 'day_filter', filterTeam: item === 'team_submitters'}">
        <p class="label" v-if="!secondary_list.includes(item)">{{keyMap[item]}}</p>
        <div class="filters day-pack-range" v-if="item === 'day_filter'">
          <!-- <el-button
            class="filter-item"
            v-for="(op, idx) in filterOption[item]"
            :key="idx"
            @click="disabledClick(op)"
            :class="{active: filters.day_filter.value === op.range, disable: !op.is_valid}"
            text
            link
          >{{op.zhcn_name}}<svg-icon name="fufei" color="#EBCE9C" v-if="!op.is_valid"></svg-icon></el-button> -->
          <el-date-picker
            v-model="filters.day_filter.value"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :editable="false"
            :disabled-date="disableDateFn"
            :start-placeholder="$t('taskhub.filter.time_start')"
            :shortcuts="shortcuts"
            :end-placeholder="$t('taskhub.filter.time_end')"
            :range-separator="$t('taskhub.filter.separator')"
            :teleported="false"
            type="daterange"
          />
        </div>
        <div class="filters" v-if="unshiftAllOption.includes(item)">
          <el-button
            class="filter-item"
            v-for="(op, idx) in filterOption[item]"
            :class="{active: filters[item].value === (op.name || op.category)}"
            @click="handleClick(item, op)"
            :key="idx"
            plain
          >{{$isZh() ? op.zhcn_name : (op.name || op.category)}}</el-button>
        </div>
        <div class="filters secondary_list_container" v-if="secondary_list.includes(item)">
          <el-button
            class="filter-item"
            v-for="(op, idx) in filterOption[active].find(v => (v.category || v.name) === secondary_active).subdivision"
            :class="{active: filters[active].children === (op.name || op.category)}"
            @click="handleClickChildren(op.name)"
            :key="idx"
            text
            link
          >{{$isZh() ? op.zhcn_name : (op.name || op.category)}}</el-button>
        </div>
        <div class="filters range" v-if="item === 'score'">
          <el-slider v-model="filters.score" range :max="10" />
          <el-input-number class="range-input" v-model="filters.score[0]" :precision="1" :controls="false" size="small" :min="0" :max="10" />
          <span>-</span>
          <el-input-number class="range-input" v-model="filters.score[1]" :precision="1" :controls="false" size="small" :min="0" :max="10" />
        </div>
        <div class="filters select" v-if="item === 'team_submitters'">
          <el-select popper-class="submitter-operate" class="owner-select" v-model="filters.team_submitters.value" clearable :placeholder="$t('taskhub.filter.submitter')">
            <el-option
              v-for="(item,index) in filterOption.team_submitters"
              :key="index"
              :label="item.user_name"
              :value="item.user_id">
            </el-option>
            <template #empty>
              <div class="empty">
                <no-data :width="60" :height="60"></no-data>
              </div>
            </template>
          </el-select>
        </div>
      </div>
      <div class="buttons">
        <div>
          <el-button class="filter" @click="search" type="success">{{$t('taskhub.filter.search')}}</el-button>
          <el-button class="reset" @click="reset" text ><svg-icon name="chongzhi"></svg-icon>{{$t('taskhub.filter.reset')}}</el-button>
        </div>
        <div v-if="tag.length">
          <span class="selected">{{$t('taskhub.filter.selected')}}</span>
          <el-button type="primary" size="small" v-for="(item, index) in tag" :key="index">
            {{item.value}}<el-icon class="el-icon--right"><Close @click="resetSelect(item.type)"/></el-icon>
          </el-button>
        </div>
      </div>
    </div>
  <!-- </el-collapse-transition> -->
   <upgrade-dialog v-model:show="isShowUpgrade" type="D" :showPoint="login" :showNormal="!login">
      <div class="permise">
        <div class="permise-tr" v-for="(tr, i) in list" :key="i">
          <div class="permise-td" v-for="(td, j) in tr" :key="j">
            {{td}}
          </div>
        </div>
      </div>
    </upgrade-dialog>
</teleport>
</template>

<script setup>
import { defineProps, toRefs, reactive, onMounted, ref, computed } from 'vue'
import { ArrowDown, Close } from '@element-plus/icons-vue'
import upgradeDialog from '@/components/common/upgradeDialog.vue'
import { getTaskFilters, getGlobalTaskFilters } from 'services/public'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import emitter from '@/app/emitter.js'
import dayjs from 'dayjs'
import { isZh, t } from 'app/i18n'
const store = useStore()
const router = useRouter()
const route = useRoute()
const props = defineProps({
  extraFilters: {
    type: Object,
    default: () => {}
  },
  pageType: {
    type: String,
    default: 'public'
  },
  isGlobal: {
    type: Boolean,
    default: false
  },
  teamId: {
    type: Array,
    default: () => []
  },
  isSearch: {
    type: Boolean,
    default: false
  },
  query: {
    type: String,
    default: ''
  },
  stayCollapse: {
    type: Boolean,
    default: false
  },
  textBtn: {
    type: Boolean,
    default: false
  }
})

const isLoaded = ref(false)

const isPublistFilterOption = ref([
  {
    label: '全部任务',
    value: -1
  },
  {
    label: '公开任务',
    value: 1
  },
  {
    label: '私有任务',
    value: 0
  }
  // {
  //   label: '已删除任务',
  //   value: -2
  // }
])

const state = reactive({
  originTime: {
    value: 'all',
    tag: ''
  },
  showFilterBtn: false,
  type: 1,
  show: false,
  secondary_show: false,
  secondary_active: '',
  active: '',
  filterOption: {},
  isShowUpgrade: false,
  filters: {
    task_type_filter: {
      value: 'all',
      children: 'all',
      tag: ''
    },
    tag_filter: {
      value: 'all',
      children: 'all',
      tag: ''
    },
    os_env_filter: {
      value: 'all',
      children: 'all',
      tag: ''
    },
    day_filter: {
      value: [],
      tag: ''
    },
    score: [0, 10],
    status_filter: {
      value: 'all',
      tag: ''
    },
    is_public_filter: {
      value: -1
    },
    team_submitters: {
      value: '',
      tag: ''
    }
  },
  tags: [],
  secondary_list: []
})
const { pageType, teamId, isSearch, query } = toRefs(props)
const { show, filterOption, showFilterBtn, isShowUpgrade, filters, tags, secondary_show, secondary_active, active, secondary_list } = toRefs(state)

const initValue = {
  task_type_filter: {
    value: 'all',
    children: 'all'
  },
  tag_filter: {
    value: 'all',
    children: 'all'
  },
  os_env_filter: {
    value: 'all',
    children: 'all'
  },
  day_filter: {
    value: [],
    tag: ''
  },
  status_filter: {
    value: 'all',
    tag: ''
  },
  is_public_filter: {
    value: -1
  },
  team_submitters: {
    value: '',
    tag: ''
  },
  score: [0, 10]
}

const list = [
  [t('other.views.taskHub.visitor'), t('other.views.taskHub.free'), t('other.views.taskHub.professional'), t('other.views.taskHub.professionalEdition')],
  [t('other.views.taskHub.mounth3'), t('other.views.taskHub.mounth3'), t('other.views.taskHub.mounth6'), t('other.views.taskHub.mounth12')]
]

const shortcuts = ref([
  {
    text: t('taskhub.filter.lastweek'),
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      return [start, end]
    }
  },
  {
    text: t('taskhub.filter.lastmonth'),
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    }
  },
  {
    text: t('taskhub.filter.last_three_month'),
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 3)
      return [start, end]
    }
  },
  {
    text: t('taskhub.filter.last_six_month'),
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 6)
      return [start, end]
    }
  },
  {
    text: t('taskhub.filter.lastyear'),
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 12)
      return [start, end]
    }
  }
])

const tag = computed(() => {
  const tag = []
  let str = ''
  if (!Object.keys(filterOption.value).length) return ''
  for (const key in filters.value) {
    const single = filters.value[key]
    if (single.value !== 'all' && unshiftAllOption.value.includes(key) && single.children) {
      if (single.children !== 'all') {
        const item = filterOption.value[key].find(v => single.value === v.name || single.value === v.category)
        const child = item?.subdivision.find(v => v.name === single.children)
        child && (str = isZh() ? child.zhcn_name : (child.name || child.category))
      } else {
        const item = filterOption.value[key].find(v => single.value === v.name || single.value === v.category)
        item && (str = isZh() ? item.zhcn_name : (item.name || item.category))
      }
    } else if (key === 'status_filter' && single.value !== 'all') {
      if (single.tag) {
        str = single.tag
      } else if (filterOption.value[key]) { // 进页面url上没有tag
        // const field = key === 'day_filter' ? 'range' : 'name'
        const tag = filterOption.value[key].find(v => v.name === single.value)
        str = isZh() ? tag.zhcn_name : (tag.name || tag.category)
      }
    } else if (key === 'day_filter') {
      if (single.value && single.value[0] && single.value[1]) {
        str = `${dayjs(single.value[0]).format('YYYY-MM-DD')} - ${dayjs(single.value[1]).format('YYYY-MM-DD')}`
      }
    } else if (key === 'is_public_filter' && props.isGlobal) {
      if (single.value === 1) {
        str = '公开任务'
      } else if (single.value === 0) {
        str = '私有任务'
      } else {
        str = '全部任务'
      }
    } else if (key === 'score') {
      if (single[0] || single[1] !== 10) {
        str = `${single[0]} - ${single[1]}`
      }
    } else if (key === 'team_submitters' && submitterTag.value) {
      str = submitterTag.value
    }
    str && tag.push({
      type: key,
      value: str
    })
    str = ''
  }
  return tag
})

const submitterTag = computed(() => {
  const obj = (filterOption.value.team_submitters || []).find(v => v.user_id === filters.value.team_submitters.value)
  if (obj) return obj.user_name
  return ''
})

const filterData = computed(() => {
  const task = {}
  const filter = {}
  for (const key in filters.value) {
    const single = filters.value[key]
    if (key === 'day_filter' && single.value && single.value[0] && single.value[1]) {
      filter.filter_start_time = new Date(dayjs(single.value[0]).format('YYYY-MM-DD 00:00:00')).getTime() / 1000
      filter.filter_end_time = new Date(dayjs(single.value[1]).format('YYYY-MM-DD 23:59:59')).getTime() / 1000
    } else if (key === 'status_filter' && single.value !== 'all') {
      filter.task_status = single.value
    } else if (key === 'is_public_filter' && props.isGlobal) {
      filter.is_public = single.value
    } else if (key === 'team_submitters' && single.value !== 'all') {
      filter.submitter_id = single.value
    } else { // 处理二级菜单
      if (single.value !== 'all') {
        if (single.children !== 'all') {
          task[key] = single.children
        } else {
          const item = filterOption.value[key].find(v => single.value === v.name || single.value === v.category)
          if (item?.subdivision.length) {
            const arr = JSON.parse(JSON.stringify((item.subdivision)))
            arr.shift()
            task[key] = arr.map(v => v.name)
          } else {
            task[key] = single.value
          }
        }
      }
    }
  }
  if (filters.value.score[0] || filters.value.score[1] !== 10) {
    filter.score_min = filters.value.score[0]
    filter.score_max = filters.value.score[1]
  }
  if (task.os_env_filter) filter.os_env = task.os_env_filter
  if (task.task_type_filter) filter.task_type = task.task_type_filter
  if (task.tag_filter) filter.tag = task.tag_filter
  return {
    ...filter
  }
})

// watch(() => filterData.value, (v) => {
//   emitter.emit('filterChange', v)
// })

const login = ref(store.getters.isLogin)

const handleClick = (key, op) => {
  const name = op.name || op.category
  const old = filters.value[key].value
  filters.value[key].value = name
  if (op.subdivision && op.subdivision.length) {
    KeyOrder.value = KeyOrder.value.filter(v => v !== secondary_active.value)
    const index = KeyOrder.value.findIndex(x => key === x)
    KeyOrder.value.splice(index + 1, 0, name)
    keyMap.value[name] = isZh() ? op.zhcn_name : (op.name || op.category)
    secondary_list.value.push(name)
    // 有二级菜单的切换时二级菜单选项重置为全部
    if (name !== secondary_active.value && active.value === key) {
      filters.value[key].children = 'all'
    } else if (active.value !== key && name !== old) {
      filters.value[key].children = 'all'
    }
    active.value = key
    secondary_active.value = name
    secondary_show.value = true
  } else {
    if (active.value === key) {
      secondary_show.value = false
      KeyOrder.value = KeyOrder.value.filter(v => v !== secondary_active.value)
    }
  }
}

const handleClickChildren = key => {
  filters.value[active.value].children = key
}

onMounted(() => {
  isLoaded.value = true
  filters.value = {
    ...filters.value,
    ...decodeUrlParams()
  }
  if (props.isGlobal) {
    getGlobalTaskFilters().then((res) => {
      handleFilter(res)
      setTimeout(() => {
        handleOption(res)
        // handleTimeRange(res)
        handleHomeTag(res)
        filterOption.value = res
        showFilterBtn.value = true
        emitter.emit(`${pageType.value}/filterChange`, filterData.value)
        tags.value = [...tag.value].map(v => v.value)
        if (props.stayCollapse) show.value = true
      }, 200)
    }).catch((e) => {
      console.log(e)
      emitter.emit('unlogin')
    })
  } else {
    getTaskFilters({
      filter_type: pageType.value,
      team_ids: teamId.value,
      query: query.value
    }).then((res) => {
      handleFilter(res)
      setTimeout(() => {
        handleOption(res)
        // handleTimeRange(res)
        handleHomeTag(res)
        filterOption.value = res
        showFilterBtn.value = true
        emitter.emit(`${pageType.value}/filterChange`, filterData.value)
        tags.value = [...tag.value].map(v => v.value)
        if (props.stayCollapse) show.value = true
      }, 200)
    }).catch((e) => {
      console.log(e)
      emitter.emit('unlogin')
    })
  }
})

const KeyOrder = ref(['day_filter', 'task_type_filter', 'tag_filter', 'os_env_filter', 'score'])

const unshiftAllOption = ref(['task_type_filter', 'tag_filter', 'os_env_filter'])

const keyMap = ref({
  day_filter: t('taskhub.filter.label.time'),
  os_env_filter: t('taskhub.filter.label.os_env'),
  tag_filter: t('taskhub.filter.label.tag'),
  task_type_filter: t('taskhub.filter.label.type'),
  team_submitters: t('taskhub.filter.label.submitter'),
  score: t('taskhub.filter.label.score')
})

if (isSearch.value) {
  delete keyMap.value.task_type_filter
  KeyOrder.value.splice(1, 1)
}

if (pageType.value === 'private_filter') {
  KeyOrder.value.splice(4, 0, 'status_filter')
  unshiftAllOption.value.push('status_filter')
  keyMap.value.status_filter = t('taskhub.filter.label.status')
} else if (pageType.value === 'team_filter') {
  KeyOrder.value.push('team_submitters')
  keyMap.value.team_submitters = t('taskhub.filter.label.submitter')
}

function handleOption(res) {
  unshiftAllOption.value.map((v) => {
    res[v].unshift({ zhcn_name: t('taskhub.history.all'), name: 'all' })
  })
  for (let i = 0; i < res.task_type_filter.length; i++) {
    if (res.task_type_filter[i].subdivision && res.task_type_filter[i].subdivision.length > 0) {
      for (let j = 0; j < res.task_type_filter[i].subdivision.length; j++) {
        const obj = res.task_type_filter[i].subdivision[j]
        res.task_type_filter[i].subdivision[j] = { zhcn_name: obj, name: obj }
      }
    }
  }
  for (const key in res) {
    res[key].map(v => {
      if (v.subdivision && v.subdivision.length > 0) {
        v.subdivision.unshift({ zhcn_name: t('taskhub.history.all'), name: 'all' })
      }
    })
  }
  res.score =
    {
      label: t('taskhub.filter.label.score'),
      type: 'range'
    }
}

// function handleTimeRange(res) {
//   if (pageType.value === 'public_filter') {
//     const arr = [...res.day_filter]
//     let item = arr.reverse().find(v => v.is_valid)
//     const url_day_filter = decodeUrlParams()?.day_filter || 0
//     // url上没有时间参数 取用户最大权限range
//     if (url_day_filter) {
//       if (item.range >= url_day_filter.value) {
//         item = arr.find(v => v.range === url_day_filter.value)
//       } else {
//         clearInvalidKey('day_filter')
//       }
//     }
//     originTime.value.value = item.range
//     originTime.value.tag = item.zhcn_name
//     filters.value.day_filter.value = item.range
//     filters.value.day_filter.tag = item.zhcn_name
//     tags.value = [item.zhcn_name]
//   } else {
//     res.day_filter.unshift({ zhcn_name: '全部', range: 'all', is_valid: true })
//   }
// }

function clearInvalidKey(key) {
  const path = route.path
  const newQuery = JSON.parse(JSON.stringify(route.query))
  invalid()
  let filter = JSON.parse(newQuery.filter)
  delete filter[key]
  filter = JSON.stringify(filter)
  // router.replace({ path, query: newQuery })
  router.replace({ path, query: { ...route.query, filter } })
  // router.go(0)
}

function handleFilter(res) {
  let { filter } = route.query
  filter = filter ? JSON.parse(filter) : {}
  const keys = Object.keys(res)
  keys.push('score')
  for (const k in filter) {
    if (!keys.includes(k)) {
      clearInvalidKey(k)
    }
    const contains = ['os_env_filter', 'tag_filter', 'task_type_filter']
    if (contains.includes(k)) {
      const arr = res[k].map(v => v.category || v.name)
      if (!arr.includes(filter[k].value) && filter[k].children) {
        clearInvalidKey(k)
        filters.value[k].value = 'all'
        filters.value[k].children = 'all'
      }
      const subArr = ['all']
      res[k].map(v => {
        if (v.subdivision) {
          v.subdivision.map(x => subArr.push(x.name || x))
        }
      })
      if (!subArr.includes(filter[k].children)) {
        clearInvalidKey(k)
        filters.value[k].children = 'all'
        filters.value[k].value = 'all'
      }
    }
  }
}

function handleHomeTag(res) {
  const { homeTag } = route.query
  const path = route.path
  let bol
  if (homeTag) {
    for (let i = 0; i < res.tag_filter.length; i++) {
      if (res.tag_filter[i].subdivision) {
        for (let j = 0; j < res.tag_filter[i].subdivision.length; j++) {
          if (res.tag_filter[i].subdivision[j].name === homeTag) {
            filters.value.tag_filter = {
              value: res.tag_filter[i].category,
              children: res.tag_filter[i].subdivision[j].name
            }
            bol = true
          }
        }
      }
    }
    if (bol) {
      const obj = JSON.stringify(encodeUrlParams())
      router.replace({ path, query: { filter: obj } })
    } else {
      toClearPath()
    }
  }
}

function toClearPath() {
  const path = route.path
  invalid()
  router.replace({ path })
}

function disableDateFn(date) {
  const currentTime = new Date().getTime()
  if (date.getTime() > currentTime) {
    return true
  }
  return false
}

function invalid() {
  store.commit('app/showMessage', {
    type: 'error',
    message: t('taskhub.filter.invalid'),
    duration: 3000
  })
}

// function disabledClick(op) {
//   if (!op.is_valid) {
//     isShowUpgrade.value = true
//   } else {
//     filters.value.day_filter.value = op.range
//     filters.value.day_filter.tag = op.zhcn_name
//   }
// }

function encodeUrlParams() {
  const data = JSON.parse(JSON.stringify(filters.value))
  const arr = ['day_filter', 'status_filter']
  const params = {}
  for (const key in data) {
    if (arr.includes(key) && data[key].value !== 'all' && data[key].value !== null) {
      params[key] = {
        value: data[key].value
      }
    } else if (unshiftAllOption.value.includes(key) && data[key].value !== 'all') {
      params[key] = {
        value: data[key].value,
        children: data[key].children
      }
    } else if (key === 'score' && (data.score[0] || data.score[1] !== 10)) {
      params[key] = data[key]
    } else if (key === 'team_submitters' && data[key].value) {
      params[key] = data[key]
    }
  }
  return params
}

function decodeUrlParams() {
  // const path = route.path
  // const legal = ['filter', 'page']
  // const newQuery = JSON.parse(JSON.stringify(route.query))
  // legal.map(v => {
  //   for (const key in route.query) {
  //     if (legal.indexOf(key) === -1) {
  //       invalid()
  //       delete newQuery[key]
  //     }
  //   }
  // })
  // router.replace({ path, query: newQuery })
  const { filter } = route.query
  return filter ? JSON.parse(filter) : {}
}

function search() {
  tags.value = [...tag.value].map(v => v.value)
  const type = isSearch.value ? 'search' : pageType.value
  emitter.emit(`${type}/filterChange`, filterData.value, true)
  const obj = JSON.stringify(encodeUrlParams())
  const path = route.path
  router.replace({ path, query: { ...route.query, filter: obj } })
}

function resetSelect(key) {
  try {
    filters.value[key] = JSON.parse(JSON.stringify(initValue))[key]
    if (key === active.value) {
      KeyOrder.value = KeyOrder.value.filter(v => v !== secondary_active.value)
      secondary_active.value = ''
      active.value = ''
    }
  } catch (e) {
    console.log(e)
  }
}

function reset() {
  filters.value = JSON.parse(JSON.stringify(initValue))
  KeyOrder.value = KeyOrder.value.filter(v => v !== secondary_active.value)
  secondary_active.value = ''
  active.value = ''
  tags.value = [...tag.value].map(v => v.value)
  search()
}

// extraFilters && extraFilters.value && keyMap.push(extraFilters.value)
</script>
<style lang="scss">
.submitter-operate {
  border: 0 !important;
  box-shadow: var(--shadow) !important;
  .submitter-operate {
    box-shadow: none !important;
  }
  .el-popper__arrow {
    display: none;
  }
  .el-select-dropdown__item {
    width: 195px;
  }
  .disabled {
    cursor: not-allowed;
    color: #525966;
  }
  .arrow-item {
    width: 100px;
    height: 22px;
    display:flex;
    justify-content:space-between;
    align-items:center;
  }
  .empty {
    padding: 12px 0;
  }
}
</style>
<style lang="scss" scoped>
[lang="en"] .wrapper .filter-type {
  .label {
    width: 68px;
  }
  .filters {
    max-width: calc(100% - 98px);
    :deep(.owner-select) {
      width: 220px;
    }
  }
}
.wrapper {
  // background-color: var(--detect-config-bg);
  background-color: var(--color-bg-3);
  padding: 10px 20px 20px;
  margin: 12px 0 6px;
  position: relative;
  .filter-type {
    display: flex;
    align-items: center;
    padding: 12px 12px;
    position: relative;
    // box-shadow: 0 1px 0 0 var(--color-border-2);
    &:first-child {
      margin-top: 0;
    }
    .label {
      width: 58px;
      position: absolute;
      margin: 0;
      font-size: 14px;
      line-height: 18px;
      color: var(--color-text-1);
      padding-right: 32px;
      align-self: flex-start;
    }
    :deep(.el-radio__label) {
      color: var(--color-text-2);
    }
    .demo-tabs {
      :deep(.el-tabs__header) {
        margin: 0;
        .el-tabs__nav-wrap::after {
          display: none;
        }
        .el-tabs__nav-next, .el-tabs__nav-prev {
          top: -4px;
        }
        & .is-active {
          border: 1px solid  var(--el-color-primary) !important;
          i {
            &.active {
              transform: rotateZ(180deg);
            }
          }
        }
        & .is-active + .el-tabs__item {
          border-left: none !important;
        }
        .el-tabs__item {
          height: 32px;
          line-height: 32px;
          border: 1px solid var(--color-text-3);
          border-right: none;
          box-sizing: border-box;
          .el-icon {
            position: relative;
            top: 2px;
            transition: all 0.5s;
          }
          &:last-child {
            padding-right: 24px;
            border: 1px solid var(--color-text-3);
            border-radius: 0 2px 2px 0;
          }
          &:nth-child(2) {
            padding-left: 24px;
            border-radius: 2px 0 0 2px;
          }
        }
        .el-tabs__active-bar {
          display: none;
        }
      }
    }
  }
  .filterRange {
    padding: 7px 12px !important;
    .label {
      align-self: center;
    }
  }
  .dayRange {
    padding: 7px 12px !important;
    .label {
      align-self: center;
    }
  }
  .filterTeam {
    padding: 10px 12px !important;
    .label {
      align-self: center;
    }
  }
  .range {
    display: flex;
    align-items: center;
  }
  .day-pack-range {
    margin-top: 0 !important;
  }
  .select, .range {
    margin-top: 0 !important;
    :deep(input) {
      height: 24px;
    }
  }
  .filters {
    max-width: calc(100% - 88px);
    margin: -19px 0 0 88px;
    &.filter-range {
      margin-top: -5px;
    }
    .filter-item {
      margin:12px 12px 0 0;
    }
    .active {
      color: var(--color-priamry);
    }
    .disable {
      color: var(--color-text-3);
      cursor: not-allowed;
    }
    svg {
      margin-left: 4px;
    }
    :deep(.el-slider) {
      width: 200px;
      margin-right: 20px;
      .el-slider__runway {
        background-color: var(--slider-runway);
      }
    }
    :deep(.el-date-editor) {
      .el-range__close-icon {
        color: var(--color-text-2);
        top: 0;
      }
      .el-range-input {
        -webkit-user-select:none;
        -moz-user-select:none;
        -ms-user-select:none;
        user-select:none;
      }
    }
    :deep(.el-picker__popper) {
      top: 44px !important;
      left: 12px !important;
      // box-shadow: 0 9px 28px 8px rgba(0,0,0,.2),0 6px 16px 0 rgba(0,0,0,.3215686275),0 3px 6px -4px rgba(0,0,0,.4784313725)!important;
      box-shadow: var(--shadow);
      .el-popper__arrow {
        display: none;
        // top: -5px !important;
        // left: 300px !important;
      }
      .el-picker-panel__sidebar {
        border-right-color: var(--datePicker-dividing-line);
        .el-picker-panel__shortcut {
          line-height: 34px;
          -webkit-user-select:none;
          -moz-user-select:none;
          -ms-user-select:none;
          user-select:none;
        }
      }
      .el-date-table-cell__text {
        box-sizing: border-box;
      }
    }
    .range-input {
      width: 32px;
    }
    span {
      padding: 4px;
    }
    :deep(.el-input--small) {
      width: 32px;
      .el-input__wrapper {
        padding: 0;
      }
    }
    .deep {
      padding:10px 20px;
      background: #222a36;
      border: 1px solid #5a5e6d66;
      border-radius: 0px 0px 2px 2px;
    }
  }
  .secondary_list_container {
    border: 1px solid var(--color-border-2);
    padding: 0 0 10px 12px;
    margin-top: -12px;
    margin-bottom: 2px;
    .filter-item {
      margin:12px 24px 0 0;
    }
  }
  .buttons {
    display: flex;
    flex-direction: row-reverse;
    justify-content:space-between;
    margin-top: 24px;
    padding-left: 12px;
    align-items: center;
    .selected {
      padding-right: 32px;
    }
    // position: absolute;
    // bottom: 20px;
    // right: 22px;
    .reset {
      border: none;
      color: var(--color-priamry);
    }
    .filter {
      width: 78px;
      height: 32px;
      border: none;
      background: #009e71;
      border-radius: 2px;
      :deep(span) {
        color: #fff;
      }
    }
  }
}
.public-list-option {
  position: relative;
  top: -34px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  button {
    background-color: transparent;
    color: var(--color-priamry);
    border-color: var(--color-priamry);
    svg {
      transition: all 0.5s;
    }
    i {
      & .active {
        transform: rotateZ(180deg);
      }
    }
  }
  .public-list-tag {
    display: inline-block;
    padding: 0 27px 0 10px;
    margin-right: 6px;
    color: var(--color-text-1);
    height: 26px;
    line-height: 26px;
    box-sizing: border-box;
    font-size: 14px;
    background: #364984;
    border-radius: 4px;
    position: relative;
    &-name {

    }
    &-close {
      position: absolute;
      right: 10px;
      top: -1px;
      bottom: 0px;
      margin: auto;
      cursor: pointer;
    }
  }
  .option-filter {
    height: 32px;
    cursor: pointer;
  }
  .filter-tag {
    display: flex;
    flex-direction: row-reverse;
    span {
      background: var(--color-bg-4);
      border-radius: 2px;
      font-size: 14px;
      color: var(--color-text-1);
      padding: 8px 12px;
      margin-right: 16px;
    }
  }
}
.permise {
  border: 1px solid var(--color-border-2);
  border-radius: 4px;
  &-td {
    flex: 1;
    text-align: center;
    border-right: 1px solid var(--color-border-2);
    border-bottom: 1px solid var(--color-border-2);
  }
  &-tr {
    display: flex;
    height: 50px;
    line-height: 50px;
    &:first-child {
      color: var(--color-text-1);
      height: 40px;
      line-height: 40px;
    }
    & .permise-td {
      &:last-child {
        border-right: 0;
      }
    }
    &:last-child {
      color: var(--color-text-2);
      & .permise-td {
        border-right-color: #3f4251;
        border-bottom: 0;
        &:last-child {
          border-right-color: transparent;
        }
      }
    }
  }
}
</style>
